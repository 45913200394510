<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <BreadCrumb :links="[
          {
            name: 'Fabric Translate'
          },
          {
            name: 'Fabric Translate Application',
            params: {
              auuid: $route.params.auuid
            }
          },
          {
            name: 'Fabric Translate Extracted Pages',
            params: {
              auuid: $route.params.auuid,
              tpuuid: $route.params.tpuuid
            }
          }
        ]" />
      </div>
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body text-left">
            <div class="row no-gutters display-3 align-items-center py-0 my-0 display-4">
              <div class="flex-shrink-1 pr-4">
                <h5 class="m-0">{{ getApplicationFullName }}</h5>
              </div>
              <div class="flex-shrink-1 pr-4">
                <h6 class="m-0"><strong>DOB:</strong> {{ getDOB }}</h6>
              </div>
              <EmploymentForm ref="employmentForm" v-if="getTracedPensionEmployment" route="traced-pension"
                :employment-data="getTracedPensionEmployment" buttonText="edit employment details" />
              <EmploymentFormAdd ref="employmentFormAdd" v-if="!getTracedPensionEmployment" route="traced-pension"
                buttonText="add employment details" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="card w-100 p-0 mb-4">
          <div class="card-body w-100 container p-0">
            <div class="row w-100 no-gutters py-4 col-12 align-items-center">
              <div class="col-1">
                <SelectInput class="page-selector mr-2 mb-0" name="pdfPage" placeholder="" :options="getSelectList"
                  @selected="handleSelected" :value="getPDFPage + 1" />
              </div>
              <div class="col-10 row no-gutters justify-content-center">
                <ul class="pagination mb-0" :class="{ 'pagination-lg': true }">
                  <li class="page-item" :class="{ 'disabled': getPageIndex < 2 }">
                    <a class="page-link" href="#" aria-label="Start" @click="handleSelected(1)">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item" :class="{ 'disabled': getPageIndex < 2 }">
                    <a class="page-link" href="#" aria-label="Previous" @click="handleSelected(getPageIndex - 1)">
                      <span aria-hidden="true">&lt;</span>
                    </a>
                  </li>
                  <li v-for="(link, ind) in getPageList" class="page-item"
                    :class="{ 'active': link.id === getPageIndex }" :key="ind">
                    <a class="page-link" href="#" aria-label="link.name " @click="handleSelected(link.id)">
                      {{ link.name }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ 'disabled': (getPageCount - getPageIndex) < 1 }">
                    <a class="page-link" href="#" aria-label="Next" @click="handleSelected(getPageIndex + 1)">
                      <span aria-hidden="true">&gt;</span>
                    </a>
                  </li>
                  <li class="page-item" :class="{ 'disabled': (getPageCount - getPageIndex) < 1 }">
                    <a class="page-link" href="#" aria-label="End" @click="handleSelected(getPageCount)">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-1">
                <div v-show="getPageCount" class="text-size-20 font-weight-bold text-nowrap">{{ getPageIndex }} of {{
                    getPageCount
                }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-auto spacer" v-if="getPDFArray">
            <EntityIframeWrapper v-for="(pdf, index) in getPDFArray" :pdf-data="pdf" :key="index" :ref-name="index" />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row m-0 p-0">
          <div class="col-12 pdf-control-spacer mb-3">
            <div class="card p-0">
              <div class="card-body pdf-control pt-3.5 pb-4">
                <SearchBlock />
              </div>
            </div>
          </div>
        </div>
        <Form class="w-100 text-left" @submit="handlePopulateReport" :validation-schema="schema">
          <Entities />
          <div class="row">
            <div class="col-12">
              <button class="fabric-btn fabric-btn-submit" :disabled="getIsEntityUpdating">
                Finish
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import SearchEntity from "@/components/search/SearchEntity";
import SearchBlock from "@/components/search/SearchBlock";
import Entities from "@/components/ml-operations/Entities";
import EntityIframeWrapper from "@/components/ml-operations/ml-doc-interface/EntityIframeWrapper";
import EmploymentForm from "@/components/form/EmploymentForm";
import EmploymentFormAdd from "@/components/form/EmploymentFormAdd";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "MLPageReview",
  setup() {
    const schema = Yup.object().shape({});
    const shownPageCount = 5;
    const shownPageSpread = Math.floor(shownPageCount / 2);

    return {
      schema,
      shownPageCount,
      shownPageSpread,
    };
  },
  components: {
    Entities,
    SearchBlock,
    EntityIframeWrapper,
    EmploymentForm,
    EmploymentFormAdd
  },
  computed: {
    ...mapGetters([
      "getApplication",
      "getApplicationFullName",
      "getMLTextEntitiesConst",
      "getMLTextAllBlocks",
      "getMLTextEntities",
      "getStoredAggregationReport",
      "getMLPageCount",
      "getPDFPage",
      "getMlInformationSource",
      "getPDFArray",
      "getTracedPension",
      "getIsEntityUpdating"
    ]),
    getDOB() {
      return this.formatReadDate(this.getApplication.dob);
    },
    getPageIndex() {
      return this.getPDFPage + 1
    },
    getPageCount() {
      return this.getMLPageCount;
    },
    getSelectList() {
      let pageList = [];
      for (const i of Array(this.getMLPageCount).keys()) {
        pageList.push({
          id: i + 1,
          name: i + 1
        });
      }
      return pageList;
    },
    getPageList() {
      let pageList = [];

      let index = Math.max(1, this.getPageIndex - this.shownPageSpread);
      if (index >= this.getPageCount - this.shownPageSpread * 2) index = this.getPageCount - this.shownPageSpread * 2;
      const max = Math.min(this.getPageCount, index + (this.shownPageSpread * 2));

      for (; index <= max; index++) {
        pageList.push({
          id: index,
          name: index
        });
      }
      return pageList;
    },
    tracedPension() {
      return this.getTracedPension;
    },
    getTracedPensionEmployment() {
      return typeof this.tracedPension !== "undefined"
        ? this.tracedPension?.employment !== null &&
          typeof this.tracedPension.employment !== "undefined"
          ? this.tracedPension.employment[0]
          : null
        : null;
    },
  },
  methods: {
    ...mapMutations([
      "setLoading",
      "setNotification",
      "setPDFPage"
    ]),
    ...mapActions([
      "clearMLData",
      "getMLData",
      "updateAggregationReport",
      "getAggregationReport",
      "updateML",
      "getTracedPensionFocus",
      "sendLearningData"
    ]),
    handleSelected(val) {
      if (this.getPageIndex === val || val < 1 || val > this.getMLPageCount) return;
      this.setPDFPage(val - 1);
    },
    async handlePopulateReport() {
      this.$router.push({
        name: "Pension Review",
        params: {
          tpuuid: this.$route.params.tpuuid
        }
      });
    }
  },
  async beforeMount() {
    this.setLoading({ bool: true });

    let auuid = this.$route.params.auuid;
    let tpuuid = this.$route.params.tpuuid;

    await this.clearMLData();

    await this.getTracedPensionFocus(tpuuid).catch(err =>
      console.log(err)
    );

    let mlResponse = await this.getMLData({ auuid, tpuuid }).catch(err => {
      this.setNotification({
        show: true,
        type: "error",
        message: "Fabric Translate Data Error!",
        content: this.formulateErrorString(err)
      });
    });

    let aggResponse = await this.getAggregationReport(tpuuid).catch(err =>
      console.error(err.response.data.error)
    );

    await Promise.all([mlResponse, aggResponse]);

    this.setLoading({ bool: false });
  },
  mounted() { }
};
</script>
<style lang="scss" scoped>
.spacer {
  width: $iframeWidth;
  height: $iframeHeight;
  padding: 0;
  margin: 0;
  outline-width: 4px;
  outline-color: black;
  outline-style: solid;
}

.stick {
  position: initial;
}

.pdf-control {
  display: flex;
  align-items: flex-end;

  &-spacer {
    width: $iframeWidth;
    padding: 0;
    margin: 0;
  }
}

.page-link {
  text-decoration: none;
}
</style>

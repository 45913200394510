<template>
  <div class="entity-option">
    <div class="entity-option-row">
      <p>{{ selectedText || match.Text }}</p>
      <input
        type="radio"
        :value="selectedText"
        :name="$parent.$parent.entityItem.FieldName"
        @click="handleSelect"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "EntityOptions",
  props: {
    match: {
      default: null
    },
    name: {
      default: null
    },
    type: {
      default: null
    }
  },
  computed: {
    ...mapGetters([
      "getMLTextAllBlocks",
      "getMLSelectedTextEntitiesIndexArr",
      "getMLSelectedTextEntitiesIndex"
    ]),
    selectedText() {
      if (!this.$parent.$parent.active) return;
      let text = "";
      let textIdArray = [];
      for (let i in this.getMLTextAllBlocks) {
        if (!this.getMLTextAllBlocks[i]) continue;
        if (!this.getMLSelectedTextEntitiesIndexArr) continue;
        if (
          this.getMLSelectedTextEntitiesIndexArr.includes(
            this.getMLTextAllBlocks[i].id
          )
        ) {
          textIdArray.push(this.getMLTextAllBlocks[i].id);
          text += this.getMLTextAllBlocks[i].text + " ";
        }
      }

      text.trim();
      this.updateSelecetedEntityText(text);
      this.updateSelecetedEntityIdList(textIdArray);

      if (this.name) {
        this.setAggregationReportValue({
          key: this.name,
          value: text
        });
      }

      return text;
    }
  },
  methods: {
    ...mapMutations([
      "setMLSelectedTextEntities",
      "setPDFPage",
      "storeMLProcessedSelection",
      "setAggregationReportValue",
      "updateSelecetedEntityIdList",
      "updateSelecetedEntityText",
      "storeMLEditSelection"
    ]),
    handleSelect(e) {
      this.handleSelectMixin({
        e,
        name: this.name,
        mlFieldType: this.type,
        setAggregationReportValue: this.setAggregationReportValue
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.entity-option {
  border-bottom: solid 1px $lightgrey;
  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calc($spacingBase / 2);
    p {
      padding-right: calc($spacingBase / 2);
      margin: 0;
    }
  }
}
</style>

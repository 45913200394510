<template>
  <div
    :class="['entity-iframe-wrapper -visible']"
    v-if="getPDFPage == parseInt(refName)"
  >
    <EntityPlacement />
    <IFrame :source="pdfData.file" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import EntityPlacement from "@/components/ml-operations/ml-doc-interface/EntityPlacement";
import IFrame from "@/components/ml-operations/ml-doc-interface/IFrame";

export default {
  name: "EntityIframeWrapper",
  props: {
    pdfData: {
      type: Object,
      default: null
    },
    refName: {
      type: Number,
      required: true
    }
  },
  data: () => ({}),
  components: {
    EntityPlacement,
    IFrame
  },
  computed: {
    ...mapGetters(["getMLPage", "getMLPageCount", "getPDFPage"]),
    getPageList() {
      let pageList = [];
      for (const i of Array(this.getMLPageCount).keys()) {
        pageList.push({
          id: i + 1,
          name: i + 1
        });
      }
      return pageList;
    },
    pageVisible() {
      return {
        "-visible": this.getPDFPage == parseInt(this.refName)
      };
    }
  },
  methods: {
    ...mapMutations(["setPDFPage"]),
    handleSelected(val) {
      this.setPDFPage(val - 1);
    }
  }
};
</script>
<style lang="scss" scoped>
.entity-iframe-wrapper {
  position: absolute;
  opacity: 0;
  &.-visible {
    opacity: 1;
  }
}
</style>

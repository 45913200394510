<template>
  <div>
    <EntityOptionsEditable
      v-for="(add, index) in addArray"
      :key="index"
      :match="add"
      :name="name"
    />
    <div class="control-wrap mt-2">
      <span v-if="!switchBtn" class="add-btn" @click="handleAdd">Add</span>
      <div v-else>
        <span class="remove-btn" @click="handleRemove">remove</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import EntityOptionsEditable from "@/components/ml-operations/EntityOptionsEditable";
export default {
  name: "EntityAdd",
  props: {
    name: {
      default: null
    }
  },
  components: {
    EntityOptionsEditable
  },
  emits: ["process"],
  data: () => ({
    addArray: [],
    switchBtn: false
  }),
  methods: {
    ...mapMutations(["setMLEditEntity"]),
    handleAdd() {
      this.setMLEditEntity(true);
      this.addArray.push({
        Text: "Select entity"
      });
      this.switchBtn = !this.switchBtn;
    },
    handleRemove() {
      if (!confirm("Remove entity?")) return;
      this.removeAction();
    },
    removeAction() {
      this.addArray.pop();
      this.switchBtn = !this.switchBtn;
    }
  }
};
</script>
<style lang="scss" scoped>
.control-wrap {
  display: flex;
  flex-direction: row;
}
.add-btn,
.process-btn,
.remove-btn {
  font-size: 16px;
}
.add-btn {
  color: blue;
}
.remove-btn {
  color: red;
}
.process-btn {
  color: green;
}
</style>

<template>
  <div
      class="entity-item text-left mb-3"
    :class="[
      completionLevel,
      activeStyle,
      visibleEntity
    ]"
  >
    <div class="entity-header row no-gutters p-3 pl-4" @click="handleExpand">
      <icon @click="handleRuleNav" v-show="ruleApplied" class="rule-applied" :data="rule" width="13" height="13" dir="up" />
      <div class="font-weight-bold" :class="[textLinkStyle, ]">
        {{ convertTitle(entityItem.Title) }}
      </div>
      <span :class="[activeStyle]" @click="handleActive">{{
        active ? "active" : "inactive"
      }}</span>
    </div>
    <div v-if="active" class="entity-item-expanded p-3">
      <CheckInput
        type="radio"
        name="informationSource"
        label="Explicit"
        value="explicit"
        :checked-val="preSelected"
        @click="handleNotes($event, false)"
      />
      &nbsp;&nbsp;
      <CheckInput
        type="radio"
        name="informationSource"
        label="Implicit"
        value="implicit"
        :checked-val="preSelected"
        @click="handleNotes($event, true)"
      />
      &nbsp;&nbsp;
      <CheckInput
        type="radio"
        name="informationSource"
        label="Contacted"
        value="contacted"
        :checked-val="preSelected"
        @click="handleNotes($event, true)"
      />
      <span v-if="showNotes !== null && showNotes">
        <p>Notes</p>
        <textarea name="notes" rows="4" v-model="notes"></textarea>
      </span>
      <div v-if="showNotes !== null && !showNotes" class="">
        <EntityOptions
          v-for="(match, index) in entityMatches"
          :key="index"
          :match="match"
          :name="entityItem.FieldName"
          :type="entityItem.FieldType"
          :style="[
            match?.MazAI
              ? match?.BlockIds !== 'ML Hug'
                  ? {
                    'border-left': 'solid 5px rgba(255,207,139,1)',
                    'color': '#61b3de'
                  }
                  : {
                    'border-left': 'solid 5px rgba(255,207,139,1)',
                    'color': '#000'
                  }
              : {
                  'border-bottom': 'solid 1px #d9d9d9'
                }
          ]"
        />
      </div>

      <EntityAdd
        v-if="showNotes !== null && !showNotes"
        ref="entityAdd"
        :name="entityItem.FieldName"
      />
      <hr />
      <EntityInput
        v-if="showNotes !== null && active"
        ref="entityInputFields"
        :input-type="entityItem.FieldType || 'text'"
        :input-response="inputResponse"
        :name="entityItem.FieldName"
        :processing="processing"
        @updated="handleUpdate"
      />
    </div>
  </div>
</template>
<script>
import EntityOptions from "@/components/ml-operations/EntityOptions";
import EntityAdd from "@/components/ml-operations/EntityAdd";
import EntityInput from "@/components/ml-operations/EntityInput";
import { mapMutations, mapGetters, mapActions } from "vuex";
import rule from "@fa/solid/pencil-ruler.svg"

export default {
  name: "EntityItem",
  components: {
    EntityInput,
    EntityAdd,
    EntityOptions
  },
  props: {
    entityItem: {
      default: null
    }
  },
  data: () => ({
    preSelected: "",
    informationSource: null,
    notes: null,
    processing: false,
    expand: false,
    active: false,
    processed: false,
    showNotes: null,
    inputResponse: null,
    hiddenEntities: ["name_of_employer", "dob", "created_at", "Databasefield",
      "power_of_attorney", "power_of_attorney_text",
      "pension_earmarking",
      "pension_provider" ],
    rule
  }),
  watch: {
    getMLTextSearchEntities() {
      this.active = false;
    },
    getMLEntityDeactivate() {
      this.active = false;
    }
  },
  computed: {
    ...mapGetters([
      "getMLEntityDeactivate",
      "getMLTextSearchEntities",
      "getMLTextEntitiesConst",
      "getMLEditSelection",
      "getStoredAggregationReport",
      "getStoredAggregationReportValue",
      "getMLFieldType",
      "getSelecetedEntityIdList",
      "getMLSelectedTextEntitiesIndexArr",
      "getSelecetedEntityText",
      "getMlInformationSource",
      "getRagEntityRules",
      "getMLProcessedSelection"
    ]),
    ruleApplied() {
      return Object.prototype.hasOwnProperty.call(this.getRagEntityRules, this.entityItem.FieldName);
    },
    completionLevel() {
      return {
        "-red":
          this.entityItem.Matches.length == 0 &&
          this.entityItem.SelectedMatch < 0,
        "-amber":
          this.entityItem.Matches.length > 0 &&
          this.entityItem.SelectedMatch < 0 &&
          this.getStoredAggregationReportValue(this.entityItem.FieldName) ===
            "",
        "-green":
          this.getStoredAggregationReportValue(this.entityItem.FieldName) !==
            "" || this.processed == true
      };
    },
    completionTextColor() {
      return {
        "text-danger":
            this.entityItem.Matches.length == 0 &&
            this.entityItem.SelectedMatch < 0,
        "text-warning":
            this.entityItem.Matches.length > 0 &&
            this.entityItem.SelectedMatch < 0 &&
            this.getStoredAggregationReportValue(this.entityItem.FieldName) ===
            "",
        "text-color-green":
            this.getStoredAggregationReportValue(this.entityItem.FieldName) !==
            "" || this.processed == true
      };
    },
    shouldExpand() {
      return {
        "-expanded": this.active
      };
    },
    activeStyle() {
      return {
        "-active": this.active
      };
    },
    textLinkStyle() {
      return {
        "link-normal link-text": !this.active,
        "link-bold": this.active
      };
    },
    entityMatches() {
      return this.entityItem.Matches;
    },
    visibleEntity() {
      return {
        "d-none": this.hiddenEntities.includes(this.entityItem.FieldName)
      };
    }
  },
  methods: {
    ...mapActions(["dataMapField", "updateML"]),
    ...mapMutations([
      "deactivateEditEntities",
      "setNotification",
      "clearSelecetedEntity",
      "clearMLTextSearchEntities",
      "setMlInformationSource",
      "storeMLProcessedSelection",
      "setIsEntityUpdating"
    ]),
    handleRuleNav() {
      this.$router.push({
        name: "Rag Rule Manager",
      });
    },
    convertTitle(title) {
      // will need to update the document service to emulate the title name change
      switch (title) {
        case "Registered scheme name":
          title = "Scheme name";
          break;
      }

      return title;
    },
    handleNotes(e, show) {
      this.showNotes = show;
      if (!e?.target) return;
      this.informationSource = e.target.value;
    },
    async handleUpdate() {
      if (!this.getStoredAggregationReportValue(this.entityItem.FieldName)) {
        this.setNotification({
          show: true,
          type: "error",
          message: "No value selected!"
        });
        return;
      }
      this.setIsEntityUpdating(true);
      this.processing = true;
      let information_source = this.informationSource || this.preSelected;
      let fieldName = this.entityItem.FieldName;
      let notes = this.notes;

      this.setMlInformationSource({
        [fieldName]: { information_source, notes }
      });

      if (this.informationSource == "explicit") {
        let processResponse = await this.handleProcess().catch(err =>{
          this.setIsEntityUpdating(false);
          console.log(err)
        }
        );
        if (!processResponse) {
          this.processing = false;
          this.setIsEntityUpdating(false);
          return;
        }
      }

      await this.updateML({
        tpuuid: this.$route.params.tpuuid,
        formatLearningdata: this.formatLearningdata
      });

      this.setNotification({
        show: true,
        type: "success",
        message: "Entity updated successfully!"
      });
      this.setIsEntityUpdating(false);
      this.processing = false;
    },
    async handleProcess() {
      let response = await this.dataMapField({
        FieldName: this.entityItem.FieldName,
        Matches: [
          {
            Text: this.getStoredAggregationReportValue(
              this.entityItem.FieldName
            )
          }
        ]
      }).catch(err => console.log(err));

      if (this.$refs.entityAdd.switchBtn) {
        for (let i in this.getMLTextEntitiesConst) {
          if (
            this.entityItem.FieldName ==
            this.getMLTextEntitiesConst[i].FieldName
          ) {
            let block_ids = this.getMLSelectedTextEntitiesIndexArr.map(i => i);
            this.getMLTextEntitiesConst[i].Matches.push({
              BlockIds: block_ids,
              Text: this.getSelecetedEntityText
            });
          }
        }

        this.clearSelecetedEntity();

        this.$refs.entityAdd.removeAction();
      }

      this.inputResponse = response.data;

      this.processed = true;

      return true;
    },
    handleExpand() {
      if (this.active) return;
      this.expand = !this.expand;
      this.handleActive();
      this.$parent.scrollToElement(this.$el);
      this.preSelect();
    },
    handleActive() {
      if (this.active) return;
      this.deactivateEditEntities();
      setTimeout(() => {
        this.active = true;
      }, 300);
    },
    preSelect() {
      this.preSelected = this.informationSource = this.entityItem.InformationSource;
      this.notes = this.entityItem.Notes;
      switch (this.preSelected) {
        case "explicit":
          this.handleNotes(null, false);
          break;
        case "implicit":
        case "contacted":
          this.handleNotes(null, true);
          break;
        default:
          this.preSelected = this.informationSource = "explicit";
          this.showNotes = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: calc($spacingBase / 2);
}
.rule-applied {
  position: absolute;
  fill: green;
  top: 21px;
  left: 5px;
}
.entity-item {
  position: relative;
  border-bottom: solid 4px $red;
  //outline-width: 1px;
  //outline-color: $lightgrey;
  //outline-style: solid;
  cursor: pointer;
  overflow: hidden;
  &.-red {
    border-color: red;
  }
  &.-amber {
    border-color: orange;
  }
  &.-green {
    border-color: green;
  }
  &.-active {
    border-top: solid 6px $black;
    outline-color: $white;
    overflow: visible;
  }
  &-expander {
    height: 0;
    overflow: hidden;
    &.-expanded {
      height: auto;
      overflow: visible;
    }
  }
}
.entity-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    color: $red;
    &.-active {
      color: green;
    }
  }
}
</style>

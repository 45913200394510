<template>
  <div class="mt-4 p-0">
    <Form
      ref="entityInputForm"
      class="w-100 text-left pb-1"
      @submit="handleUpdate"
      :validation-schema="schema"
    >
      <TextInput
        v-if="inputType == 'Text'"
        type="text"
        label=""
        class="pr-1"
        :name="name"
        :value="selectedTextValue"
      />
      <AmountInput
        v-else-if="inputType == 'Number'"
        :name="name"
        :initial-value="selectedAmountValue"
        label=""
      />
      <DatePicker
        v-else-if="inputType == 'Date'"
        :name="name"
        :value="selectedDateValue"
        label=""
        placeholder=""
      />
      <SelectInput
        v-else-if="inputType == 'Enum'"
        :name="name"
        :options="enumValues"
        :value="selectedEnumValue"
        label=""
        placeholder=""
      />
      <LoadingButton
        class="fabric-btn fabric-btn-submit"
        :processing="processing"
        btn-text="Update"
        processing-text="Processing..."
      />
    </Form>
  </div>
</template>
<script>
import * as Yup from "yup";
import { mapGetters, mapMutations, mapActions } from "vuex";
import LoadingButton from "@/components/form/LoadingButton";

export default {
  name: "EntityInput",
  props: {
    inputType: {
      default: null
    },
    name: {
      default: null
    },
    inputResponse: {
      default: null
    },
    processing: {
      default: false
    }
  },
  emit: ["updated"],
  setup() {
    const schema = Yup.object().shape({});

    return {
      schema
    };
  },
  components: {
    LoadingButton
  },
  computed: {
    ...mapGetters([
      "getMLProcessedSelection",
      "getStoredAggregationReport",
      "getMLFieldEnum",
      "getSelecetedEntityText",
      "getStoredAggregationReportValue"
    ]),
    enumValues() {
      return this.getMLFieldEnum(this.name);
    },
    selectedEnumValue() {
      const val = this.getStoredAggregationReportValue(this.name)
      if (val !== "") return val;

      const textVal = this.getStoredAggregationReportValue(`${this.name}_text`);
      const enumValues = this.getMLFieldEnum(this.name);
      //  if the text entity is specified then we still don't know; just return the original (presumably missing) value
      return (textVal !== "") ? val : enumValues[1].id;
    },
    selectedDateValue() {
      return this.parseEntityDate(
        this.getStoredAggregationReportValue(this.name)
      );
    },
    selectedTextValue() {
      return this.getStoredAggregationReportValue(this.name);
    },
    selectedAmountValue() {
      let value = this.getStoredAggregationReportValue(this.name);
      if (typeof value !== "number") {
        value = parseInt(value.replace(/\D/g, "").replace(/\s/g, ""));
      }
      return value;
    }
  },
  methods: {
    ...mapMutations(["setAggregationReportValue", "setNotification"]),
    ...mapActions(["updateAggregationReport"]),
    async handleUpdate(value) {
      if (!value) return;
      let parsedValue = value[this.name];
      switch (this.inputType) {
        case "Number":
          parsedValue = this.formatPennies(parsedValue);
          break;
        case "Date":
          parsedValue = this.formatDate(parsedValue);
          break;
        case "Enum":
          parsedValue = parsedValue.id || parsedValue;
          break;
      }

      this.setAggregationReportValue({
        key: this.name,
        value: parsedValue
      });

      this.$emit("updated");
    }
  }
};
</script>

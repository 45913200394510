<template>
  <div class="search-block">
    <TextInput
      name="searchBlock"
      type="text"
      class="pr-1 m-0 mr-3"
      @updated="handleUpdate"
    />
    <div class="search-block-control">
      <icon
        :data="arrowRight"
        class="arrow mr-3"
        width="30"
        height="30"
        dir="left"
        color="#000"
        @click="handleNav('up')"
      />
      <icon
        :data="arrowRight"
        class="arrow mr-3"
        width="30"
        height="30"
        dir="right"
        color="#000"
        @click="handleNav('down')"
      />
      <div>{{ current }} of {{ count }} matches</div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import arrowRight from "@fa/solid/arrow-right.svg";
export default {
  name: "SearchBlock",
  data: () => ({
    arrowRight,
    count: 0,
    index: 0
  }),
  computed: {
    ...mapGetters([
      "getMLTextAllBlocks",
      "getMLTextSearchBlocks",
      "getMLSelectedTextEntitiesIndexArr"
    ]),
    navActive() {
      return {
        "#000": this.getMLTextSearchBlocks.length > 0,
        "#ccc": this.getMLTextSearchBlocks.length === 0
      };
    },
    current() {
      return this.count > 0 ? this.index + 1 : 0;
    },
    countCalc() {
      return this.count > 0 ? this.count - 1 : 0;
    }
  },
  methods: {
    ...mapMutations([
      "clearMLTextSearchBlocks",
      "setMLTextSearchBlocks",
      "setMLSelectedTextEntities",
      "clearMLSelectedTextEntities",
      "pushMLSelectedTextEntities",
      "setPDFPage"
    ]),
    handleNav(dir) {
      switch (dir) {
        case "up":
          if (this.index >= 1) {
            this.index--;
          }
          break;
        case "down":
          if (this.index < this.count - 1) {
            this.index++;
          }
          break;
      }
      const allBlocks = this.getMLTextSearchBlocks
      const searchBlock = allBlocks[this.index]
      if (!searchBlock) return;
      this.setMLSelectedTextEntities({
        ind: searchBlock.id,
        shiftDown: true,
        batch: true
      });
      this.setPDFPage(searchBlock.page - 1);
    },
    async handleUpdate(value)
    {
      await this.debounce( () => {
        this.clearMLTextSearchBlocks();
        this.clearMLSelectedTextEntities();
        this.count = this.index = 0;
        if (value === "") return;

        const allBlocks = this.getMLTextAllBlocks.concat();

        //  it's 'faster' to pop things off an array so reverse the search terms
        let searchTerms = value.toLowerCase().split(" ").reverse();
        //  drop empty string results
        searchTerms = searchTerms.filter(term => term.length);

        // get all words in doc that contain the first search term and store with their index
        const firstTerm = searchTerms.pop();
        let regex = new RegExp(firstTerm, "i");
        let results = [];

        if (searchTerms.length === 1)
        {
          //  we can be fuzzy about only one word
          allBlocks.forEach((block, index) => {
            if (regex.test(block.text.toLowerCase()))
            {
              results.push({ block, index })
            }
          });
        }
        else
        {
          // otherwise we should be more specific to avoid confusion
          allBlocks.forEach((block, index) => {
            if (block.text.toLowerCase() === firstTerm)
            {
              results.push({ block, index })
            }
          });
        }

        //  multiple contiguous words filter down the result set
        let offset = 0;
        while (searchTerms.length > 0)
        {
          offset++;
          let nextTerm = searchTerms.pop();
          let nextReg = new RegExp(nextTerm, "i");

          results = results.filter((result) => {
            let nextText = allBlocks[result.index + offset].text.toLowerCase()
            //  fuzzy if the last term, otherwise strict equality
            return (searchTerms.length) ? nextText === nextTerm : nextReg.test(nextText)
          });
        }
        //  TODO - there may be a way to add the geometry together but it will need to be done on a per match basis
        //  result.block.geometry.boundingBox.width += next.geometry.boundingBox.width

        //  if any results are left, mark them all as selected
        if (results.length > 0)
        {
          this.index = 0;
          this.count = this.matches = results.length;

          results.forEach((sb) => {
            this.setMLTextSearchBlocks(sb.block);
            this.pushMLSelectedTextEntities(sb.block.id)
          });

          this.setPDFPage(results[0].block.page - 1);
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.arrow {
  cursor: pointer;
}
.search-block {
  display: flex;
  align-items: center;

  &-control {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}
</style>

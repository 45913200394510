<template>
  <div class="card">
    <div class="card-body p-0">
      <div class="scroller">
        <EntityItem
          v-for="(entityItem, index) in getMLTextEntities"
          :key="index"
          :entity-item="entityItem"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EntityItem from "@/components/ml-operations/EntityItem";

export default {
  name: "Entities",
  components: {
    EntityItem
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      "getMLTextEntities",
      "getMLTextBlocks",
      "getMLSelectedTextEntities",
      "getMLSelectedTextEntitiesIndex",
      "getMLSelectedTextEntitiesIndexArr"
    ]),
    getEntityLength() {
      if (!this.getMLTextEntities) return;
      return this.getMLTextEntities.length;
    },
    getEntityMatches() {
      let count = 0;
      for (let i in this.getMLTextEntities) {
        if (this.getMLTextEntities[i].Matches.length > 0) {
          count++;
        }
      }
      return count;
    }
  },
  methods: {
    scrollToElement(ele) {
      var topPos = ele.offsetTop;
      var scrollerEle = document.getElementsByClassName("scroller")[0];
      scrollerEle.scrollTop = topPos;
    }
  }
};
</script>
<style lang="scss" scoped>
.scroller {
  overflow-y: scroll;
  max-height: 1084px;
  padding: 0 1px;
  padding-bottom: 300px;
}
.card-body {
  //min-height: 1020px;
}
</style>

<template>
  <div class="entity-option">
    <div class="entity-option-row">
      <p @click="handleFind">{{ match.Text }}</p>
      <input
        type="radio"
        :value="match.Text"
        :name="$parent.entityItem.FieldName"
        @click="handleSelect"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "EntityOptions",
  props: {
    match: {
      default: null
    },
    name: {
      default: null
    },
    type: {
      default: null
    }
  },
  computed: {
    ...mapGetters(["getMLTextAllBlocks", "getMLFieldType"])
  },
  methods: {
    ...mapMutations([
      "setMLSelectedTextEntities",
      "setPDFPage",
      "storeMLProcessedSelection",
      "setAggregationReportValue",
      "storeMLEditSelection"
    ]),
    handleSelect(e) {
      this.handleSelectMixin({
        e,
        name: this.name,
        mlFieldType: this.type,
        setAggregationReportValue: this.setAggregationReportValue
      });
    },
    handleFind() {
      this.setMLSelectedTextEntities({
        ind: this.match.BlockIds,
        shiftDown: true,
        batch: true
      });

      for (let i in this.getMLTextAllBlocks) {
        if (!this.getMLTextAllBlocks[i]) continue;
        if (!this.match.BlockIds) continue;
        if (this.match.BlockIds.includes(this.getMLTextAllBlocks[i].id)) {
          this.setPDFPage(this.getMLTextAllBlocks[i].page - 1);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.entity-option {
  border-bottom: solid 1px $lightgrey;
  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calc($spacingBase / 2);
    p {
      padding-right: calc($spacingBase / 2);
      margin: 0;
    }
  }
}
</style>

<template>
  <div class="card mb-4">
    <div class="card-body">
      <span class="position-absolute link-text end" @click="handleClear"
        >clear</span
      >
      <Form class="p-0" autocomplete="off">
        <TextInput
          ref="searchMl"
          name="searchMl"
          type="text"
          label="Search Entities"
          class="pr-1 m-0"
          :value="value"
          @updated="handleUpdate"
        />
      </Form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SearchEntity",
  data: () => ({
    value: ""
  }),
  computed: {
    ...mapGetters(["getMLTextEntities", "getMLTextEntitiesConst"])
  },
  methods: {
    ...mapMutations(["setMLTextSearchEntities", "clearMLTextSearchEntities"]),
    handleClear() {
      this.handleUpdate("");
      this.$refs.searchMl.clear();
    },
    handleUpdate(value) {
      this.clearMLTextSearchEntities();
      for (let i in this.getMLTextEntitiesConst) {
        let string = this.getMLTextEntitiesConst[i].Title;
        let regex = new RegExp(value, "i");
        if (regex.test(string)) {
          this.setMLTextSearchEntities(this.getMLTextEntitiesConst[i]);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.end {
  right: $spacingBase;
  z-index: 10;
}
</style>

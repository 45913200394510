<template>
  <EditModal ref="editEmploymentForm">
    <template #btn-text>
      {{ buttonText }}
    </template>
    <template #formTitle>
      Add Employment
    </template>
    <template #form>
      <Form class="w-100 text-left" @submit="saveForm" :validation-schema="schema">
        <TextInput name="name_of_employer" type="text" label="Employer" placeholder="SpaceX" class="pr-1" />
        <TextInput name="position" type="text" label="Role title" placeholder="Bossman" />
        <TextInput name="industry" type="text" label="Industry" placeholder="IT" />
        <DatePicker name="employed_from" label="Date from" placeholder="" />
        <DatePicker name="employed_to" label="Date to" placeholder="" />
        <button class="fabric-btn fabric-btn-submit">Save</button>
      </Form>
    </template>
  </EditModal>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DatePicker from "@/components/form/DatePicker";
import * as Yup from "yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export default {
  name: "EmploymentForm",
  props: {
    employmentData: {
      default: null
    },
    route: {
      default: "application"
    },
    buttonText: {
      default: 'add'
    }
  },
  emits: ["updated"],
  data: () => ({
    from_date: null
  }),
  setup() {
    const schema = Yup.object().shape({
      name_of_employer: Yup.string().required(),
      position: Yup.string().nullable(),
      industry: Yup.string().nullable(),
      employed_from: Yup.string().nullable(),
      employed_to: Yup.string().nullable()
    });
    return {
      schema
    };
  },
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters([]),
    fromDate() {
      let tempdate = this.parseDate(this.employmentData.employed_from);
      if (tempdate) {
        return tempdate;
      }
      return null;
    },
    toDate() {
      let tempdate = this.parseDate(this.employmentData.employed_to);
      if (tempdate) {
        return tempdate;
      }
      return null;
    }
  },
  methods: {
    ...mapMutations(["setNotification"]),
    ...mapActions(["submitPensionEmployment"]),
    async saveForm(params) {
      let tempParams = {
        type: "tracedPension"
      };
      tempParams.employments = [];
      tempParams.employments.push({
        name_of_employer: params.name_of_employer,
        position: params.position,
        industry: params.industry,
        employed_from: params.employed_from
          ? dayjs(params.employed_from).format("YYYY-MM-DD")
          : null,
        employed_to: params.employed_to
          ? dayjs(params.employed_to).format("YYYY-MM-DD")
          : null
      });

      let response = await this.submitPensionEmployment({
        params: tempParams,
        tpuuid: this.$route.params.tpuuid
      }).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Employment update error!",
          content: err.response.data.error
        });
      });

      if (response.status == 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Employment updated!"
        });
      }
      this.$refs.editEmploymentForm.show = false;
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
